/* global pbContactForm */

window.addEventListener('load', function () {
	const modal = document.getElementById('grading-issues-modal');
	const btn = document.getElementById('grading-issues-btn');
	const span = document.getElementsByClassName('close')[0];

	btn.onclick = function () {
		modal.style.display = 'block';
		document.getElementById('grading-issues-form').style.display = 'block';
		document.getElementById('contact-form-success-message').style.display = 'none';
		document.getElementById('contact-form-error-message').style.display = 'none';
	}

	span.onclick = function () {
		modal.style.display = 'none';
	}

	window.onclick = function (event) {
		if (event.target === modal) {
			modal.style.display = 'none';
		}
	}

	const form = document.getElementById('grading-issues-form');

	form.onsubmit = function (event) {
		event.preventDefault();

		document.getElementById('contact-form-submit').disabled = true;

		fetch(pbContactForm.ajaxUrl, {
			method: form.method,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: new URLSearchParams(new FormData(form)),
		}).then(response => {
			document.getElementById('grading-issues-form').style.display = 'none';
			const messageClass = response.ok ? 'contact-form-success-message' : 'contact-form-error-message';
			document.getElementById(messageClass).style.display = 'block';
			document.getElementById('contact-form-submit').disabled = false;
		}).catch(error => {
			document.getElementById('grading-issues-form').style.display = 'none';
			document.getElementById('contact-form-submit').disabled = false;
			document.getElementById('contact-form-error-message').style.display = 'block';
		});

	}
});
